table {
    width: 100%;
    border-collapse: collapse;
}

select {
    padding: 3px 3px;
    margin: 2px 0;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 3px;
    background: white;
    width: 220px;
}

.button {
    border: none;
    box-shadow: none;
}

#button-login {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font: 700 13.3333px Arial;
    text-decoration: none !important;
    background: #000;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    width: 70px;
    height: 36px;
}

#button-login:hover {
    background: #83898c;
}

.button-container{
    display: flex;
    margin-top: 9px;
    margin-bottom: 3px;
}

.button:hover {
    background: #83898c;
}

.buttongroup {
    float: right;
    margin-bottom: 5px;
}

.hand {
    cursor: pointer;
}

.margright10 {
    margin-right: 10px;
}

.margleft10 {
    margin-left: 10px;
}

td, th {
    border: 1px solid #999;
    text-align: left;
}

td {
    padding: 0.1rem 0.1rem 0.1rem 0.5rem;
}

th {
    padding: 0.3rem 0.3rem 0.3rem 0.5rem;
    background-color: black;
    color: white;
}

#button-nyt-udbydersystem {
    float: right;
    margin-bottom: 5px;
}

.oversigt {
    margin-bottom: 10px;
}

div.datadisplay table,
div.datadisplay tr {
    border: none;
}

div.datadisplay td {
    border: none;
    padding: 3px;
}


.margbottom-5 {
    margin-bottom: -5px;
}

/* header styling */

.everythingOnOneLine {
    float: right;
    margin-top: 10px;
    margin-bottom: 5px;
}

#newudbyder {
    float: left;
    margin-top: 10px;
    margin-bottom: 5px;
}

.addbutton {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 0.75em;
    border: 1px solid #06324B;
    border-radius: 10px;
    cursor: pointer;
    padding: 2px 6px 2px 6px;
    background-color: #06324B;
    color: white;
}

.enabledImg {
    fill: #06324B;
    stroke: black;
    cursor: pointer;
    width: 13px;
    height: 13px;
}

.pagelink {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 0.75em;
    border: 1px solid #06324B;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    padding: 2px 6px 2px 6px;
}

.currentpage {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 0.75em;
    border: 1px solid black;
    border-radius: 10px;
    background-color: #06324B;
    color: white;
    padding: 2px 6px 2px 6px;
}

.login-input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 0;
    margin-top: 10px;
}

.disabledImg {
    fill: grey;
    stroke: grey;
    width: 13px;
    height: 13px;
}

.stretch {
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
}

.app-links {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.app-link {
    flex-grow: 1;
    background-color: #fcfcfc;
    border: 1px solid #aab8c6;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
}

.app-boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.app-box {
    flex-grow: 1;
    flex-basis: 100%;
    background-color: #fcfcfc;
    border: 1px solid #aab8c6;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
}

.app-box-centered {
    flex-grow: 1;
    flex-basis: 100%;
    background-color: #fcfcfc;
    border: 1px solid #aab8c6;
    border-radius: 5px;
    padding: 10px;
    margin: auto;
    width: 50%;
}

.app-box-title {
    margin: 0;
}

.app-link h3 {
    margin: 0;
}

.app-links-container {
    clear: both;
    min-height: 100px;
}

.forside-tekst {
    margin-bottom: 40px;
}

.removeLinkDecoration {
    text-decoration: none;
    cursor: default;
}

.asBlock {
    display: block;
}

.hidden {
    display: none;
}

/* MODAL COMPONENT */
.sletKlasseModal {
    width: 600px;
    margin: auto;
    background-color: white;
}

.standard-tooltip {
    position: relative;
    display: inline-block;
}

.standard-tooltip .standard-tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #06324B;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
}

.standard-tooltip:hover .standard-tooltiptext {
    visibility: visible;
}

.visIkonEleverTop {
    max-width: 100%;
    height: auto;
    width: auto;
    padding-left: 1.6625em;
}

.sletIkonEleverTop {
    max-width: 100%;
    height: auto;
    width: auto;
}

.sletIkonKlasserTop {
    max-width: 100%;
    height: auto;
    width: auto;
    padding-left: 1.4125em;
}

.selectedCounter {
    vertical-align: top;
    padding-left: 2px;
    padding-right: 2px;
}

.version-table th {
    background-color: lightgrey;
    color: black;
}

.version-table-title {
    width: 300px;
}

.stil-tabel th {
    border: none;
    color: black;
    background-color: #c2cace;
}

.stil-tabel td {
    border: none;
    padding: 8px 0;
}

.stil-tabel tr:nth-child(even) {
    background-color: #e6eaec;
}

.stil-tabel tr:nth-child(odd) {
    background-color: #ffffff;
}

.organisation-tabel {
    margin-bottom: 10px;
}

.organisation-code-header, .organisation-cvr-header, .organisation-pnr-header {
    width: 100px;
}

#organisation-search {
    width: 100%;
    padding: 5px 0;
    margin-bottom: 10px;
}

.search-input {
    width: 100%;
    padding: 5px 0;
}

.organisationer-modal-body {
    overflow-y: scroll;
    height: 400px;
}

.organisationer-modal {
    height: 500px;
    max-width: 700px !important;
}

.organisation-confirm {
    background-color: #d1ecf1;
    padding: 10px;
    padding-bottom: 50px;
    margin-bottom: 10px;
}

.button-float-right {
    float: right;
    margin-left: 10px;
}

.button-float-left {
    float: left;
}


.full-width {
    width: 100%;
}

.tab-button {
    width: 25%;
    height: 30px;
    background-color: #e5eaeb;
    border-radius: 10px 10px 0px 0px;
    border: 0;
}

.tab-button-selected {
    width: 25%;
    height: 30px;
    background-color: #bfc9cd;
    border-radius: 10px 10px 0px 0px;
    border: 0;
}

.page-buttons {
    margin-bottom: 10px;
}
/* ********************** ALERTS STYLING ********************** */

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin-top: 1rem;
}


.closediv {
    text-align: right;

}

.btn-secondary2{
    color: #ff0000 !important;
    background-color: #6c757d;
    border-color: #6c757d;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-success{
    color: #000000;
    background-color: #c0f8c4;
    border-color: #a3f5a2;
}

.noborder {
    border: none;
}

/* ****************** END OF ALERTS STYLING ********************** */



/* ****************** BOOTSTRAP MODAL STYLING - BEGIN ****************** */

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75;
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}





.modal-open {
    overflow: hidden;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
    position: relative;
    display: inline-table;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.6rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: -ms-flexbox;
    display: block;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
    margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
    margin-right: .25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px;
    }
}

/* ****************** BOOTSTRAP MODAL STYLING - END ****************** */


html {
    font-size: 62.5%;
    /* RESETS BASELINE FONT-SIZE */
}

body {
    margin: 0 auto;
    font-family: arial, sans-serif;
    font-size: 1.4rem;
    letter-spacing: 0.0rem;
    color: #141516;
}

h1 {
    margin: 0 0 1.6rem 0;
    padding: 0;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.8rem;
}

h2 {
    margin: 3rem 0 0 0;
    padding: 0;
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 3rem;
}

h2 + p {
    margin-top: 1rem;
}

h3 {
    margin: 3rem 0 0.6rem 0;
    padding: 0;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.6rem;
}

h3 + p {
    margin-top: 1rem;
}

h4 {
    margin: 3rem 0 0 0;
    padding: 0;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.0rem;
}

h4 + p {
    margin-top: 1rem;
}

p {
    max-width: 65rem;
    /* MAX LINE-WIDTH FOR READABILITY */
    margin: 1rem 0 0.5rem 0;
    padding: 0;
    font-size: 1.4rem;
    line-height: 2.2rem;
}

ul {
    line-height: 2.4rem;
}

a {
    text-decoration: underline;
    color: #004e6c;
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
}

a:hover {
    background: #e7ebed;
}

a.visited {
    text-decoration: underline;
    color: #a42104;
}

a.darkBG {
    color: #c4cbcf;
}

a.darkBG:hover {
    background: #141516;
}

a.heading {
    color: #141516;
}

a.heading:hover {
    text-decoration: underline;
}

.manchet {
    margin: 1rem 0 2rem 0;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.2rem;
}

.manchet + p {
    margin-top: 3rem;
}

.stil-site {
    max-width: 1200px;
    min-width: 768px;
    margin: 0 auto;
    padding: 0 10px 20px 10px;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.stil-header {
    display: table;
    width: 100%;
    height: auto;
    /* TODO  -  Borderen skal væk når der kommer en menu */
    border-bottom: 3px solid #000;
}

.stil-header-content {
    float: left;
    width: 100%;
    height: 90px;
}

.stil-header-content em {
    float: left;
    display: inline-block;
    margin: 50px 0 10px 0;
    /* SPECIFICALLY FOR STIL LOGO USE WITHOUT SEARCH. MARGIN-BOTTOM NEEDS ADJUSTMENT TO 10PX IF SEARCH IS VISIBLE. MARGIN ADJUSTMENTS ARE NEEDED TO COMPENSATE FOR SITE NAMELENGTH AND CHOICE OF LOGO */
    /* margin:68px 0 18px 0; /* USED WHEN SEARCH IS VISIBLE */
    /* margin:36px 0 18px 0; /* USED WHEN LOGGED IN */
    padding: 0;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: bold;
    line-height: 2.4rem;
}

.stil-header-content em a {
    color: #000000;
    text-decoration: none;
}

.stil-header-content em a:hover {
    background: transparent;
}

.stil-header-content img {
    /* display: none; */
    /* HIDING LOGO WHEN LOGGED IN TO SAVE SPACE */
    float: right;
    height: 65px;
    width: 170px;
    margin-top: 10px;
}

.stil-global-search {
    /* HIDDEN ON SMALL SCREENS */
    clear:left;
    display: none;
    /* SET TO BLOCK TO VIEW SEARCH */
    height: 28px;
    margin-bottom: 18px;
    padding: 0;
}

.stil-global-search span {
    display: table-cell;
}

.stil-global-search input {
    width: 480px;
    height: 28px;
    margin: 0;
    padding: 3px;
    border: 1px solid #000;
    border-radius: 5px;
    vertical-align: middle;
}

.stil-global-search button {
    margin: 0;
    padding: 0;
    width: 28px;
    height: 28px;
    background: transparent;
    border: 0;
    vertical-align: middle;
}

.stil-global-search path {
    fill: #4f5355;
}

.stil-global-search:hover path {
    fill: #83898c;
}

.stil-topmenu {
    clear: both;
    float: left;
    width: 100%;
    min-height: 24px;
    padding: 0;
    border-top: 1px solid #c4cbcf;
    border-bottom: 1px solid #c4cbcf;
}

.stil-topmenu .topmenuLeft,
.stil-topmenu .topmenuRight {
    display: block;
    float: left;
    list-style: none;
    width: auto;
    margin: 0;
    padding: 0;
}

.stil-topmenu .topmenuRight {
    float: right;
}

.stil-topmenu .topmenuLeft li,
.stil-topmenu .topmenuRight li {
    display: inline-block;
    margin: 0 2.4rem 0 0;
    padding: 0;
}

.stil-topmenu .topmenuRight li {
    margin: 0;
}

.stil-logged-in-user {
    float: right;
    margin: 0;
    font-size: 1.4rem;
}

.stil-topmenu .topmenuLeft li a {
    color: #4f5355;
    /* STIL ANTRACITGRÅ 2 */
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 5rem;
    padding: 1.2rem 0;
    text-decoration: none;
    background: #ffffff;
}

.stil-topmenu .topmenuLeft li a:hover {
    color: #004e6c;
    border-bottom: 6px solid #004e6c
}

.stil-primary-button {
    display: inline-block;
    margin-top: 8px;
    padding: 5px 10px;
    color: #fff;
    font: 700 13.3333px Arial;
    text-decoration: none !important;
    background: #000;
    border-radius: 5px;
    cursor: pointer;
}
.stil-notifikation-button {
    margin-top: 9px;
    margin-bottom: -8px;
    text-align: center;
    position: relative;
    display: inline-block;
    padding: 0px 10px;
    color: #000;
    cursor: pointer;
}

.stil-primary-button:hover {
    background: #83898c;
}

.stil-disabled-button {
    display: inline-block;
    margin-top: 8px;
    padding: 5px 10px;
    color: #fff;
    font-weight: bold;
    text-decoration: none !important;
    background: #ccc;
    border-radius: 5px;
}

.stil-disabled-button:hover {
    background: #ccc;
}

.stil-topmenu .mobileMenuButton {
    display: none;
    /* HIDES BUTTON ON BIG SCREENS */
}

.stil-main-content {
    clear: both;
    min-height: 300px;
}

.stil-footer {
    clear: both;
    float: left;
    width: 100%;
    margin-bottom: 80px;
    border-top: 3px solid #000;
}

.stil-footerblock {
    width: 33.333%;
    margin: 0;
    float: left;
}

.stil-footer em {
    display: block;
    max-width: 65rem;
    margin: 40px 0 10px 0;
    padding: 0;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase
}

.stil-footer p {
    max-width: 65rem;
    margin: 0;
    padding: 0 0 20px 0;
}

.stil-footer a {
    display: inline-block;
}

.notifikation-box {
    list-style-type: none;
    text-align: left;
    border: 1px solid black;
    margin-bottom: 10px;
    padding: 5px;
}

@supports (-moz-appearance:none) {
    a:focus {
        outline: 1px black;
        outline-style: dotted;
    }
    button:focus {
        outline: 1px black;
        outline-style: dotted;
    }
}


